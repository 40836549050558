<template>
  <page-layout page-name="申请开通账号" :show-home="false">
    <md-field class="main">
      <ValidationObserver ref="form">
        
        <md-input-item
          class="input-item"
          type="text"
          placeholder="联系人姓名"
          v-model="formData.contactPerson"
          is-title-latent
          :maxlength="5"
          is-highlight
          title="您的姓名"
          :disabled="querying"
        >
          <md-icon name="user" slot="left" color="#9579E5"></md-icon>
        </md-input-item>
        <md-input-item
          class="input-item"
          type="digit"
          placeholder="联系人手机号"
          v-model="formData.phone"
          is-title-latent
          is-amount
          :maxlength="11"
          is-highlight
          title="您的手机号"
          :disabled="querying"
        >
          <md-icon name="mobile-phone" slot="left" color="#9579E5"></md-icon>
        </md-input-item>
        <md-input-item
          class="input-item"
          type="text"
          placeholder="性别"
          v-model="genderName"
          is-title-latent
          readonly
          :maxlength="2"
          is-highlight
          title="您的性别"
          @focus="handleGenderSelectorShow"
          :disabled="querying"
        >
          <md-icon name="check" slot="left" color="#9579E5"></md-icon>
        </md-input-item>
        <md-input-item
          v-model="formData.enterpriseName"
          class="input-item"
          type="text"
          placeholder="企业名称（选填）"
          is-title-latent
          is-highlight
          :maxlength="35"
          title="企业名称"
          readonly
          :disabled="querying"
          @focus="handleEnterpriseSelectorShow"
        >
          <md-icon name="info" slot="left" color="#9579E5"></md-icon>
        </md-input-item>
        <md-button
          class="submit-btn"
          type="primary"
          size="small"
          :plain="true"
          @click="matching"
          :inactive="querying"
          :loading="querying"
          >{{ submitBtn }}</md-button
        >
      </ValidationObserver>
    </md-field>
    <enterprise-selector
      v-model="enterprise"
      ref="enterprise-selector"
    ></enterprise-selector>
    <gender-selector
      v-model="genderName"
      ref="gender-selector"
    ></gender-selector>

    <van-popup
      v-model="popup.show"
      :closeable="false"
      :close-on-click-overlay="false"
      position="bottom"
      round
      class="popup-message"
    >
      {{ popup.message }}
    </van-popup>
  </page-layout>
</template>

<script>
import { Search, Popup, Picker, Notify } from "vant";
import EnterpriseSelector from "@/components/EnterpriseSelector";
import GenderSelector from "@/components/GenderSelector";
import BookingApi from "@/api/booking";

export default {
  components: {
    [Search.name]: Search,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Notify.name]: Notify,
    EnterpriseSelector,
    GenderSelector,
  },
  data: function () {
    return {
      formData: {
        entName: "",
        contactPerson: "",
        phone: "",
        gender: "",
        goodsId: "无",
        goodsTypeId: "ACCOUNT_OPEN"
      },
      enterprise: {
        enterpriseName: "",
      },
      genderName: "",
      querying: false,
      popup: {
        show: false,
        message: "",
      },
    };
  },
  watch: {
    enterprise: {
      deep: true,
      handler(newVal) {
        this.formData.entName = newVal.enterpriseName;
      },
    },
    genderName(newVal) {
      if (newVal == '先生') {
        this.formData.gender = 1;
      } else if (newVal == '女士') {
        this.formData.gender = 2;
      }
    },
  },
  computed: {
    submitBtn() {
      return this.querying ? "提交中" : "提交申请";
    },
  },
  methods: {
    showPopup(message) {
      this.popup.show = true;
      this.popup.message = message;
    },
    handleEnterpriseSelectorShow() {
      this.$refs["enterprise-selector"].open();
    },
    handleGenderSelectorShow() {
      this.$refs["gender-selector"].open();
    },
    matching() {
      this.$refs.form.validate().then((res) => {
        if (!res) {
          return;
        }
        if (!this.formData.contactPerson) {
          Notify("联系人姓名不能为空");
          return;
        }
        if (!this.formData.phone) {
          Notify("联系人手机号不能为空");
          return;
        }
        if (!this.formData.gender) {
          Notify("性别不能为空");
          return;
        }
        if (!this.formData.entName) {
          this.formData.entName = "无"
        }
        this.querying = true;
        BookingApi
          .save(this.formData)
          .then((res) => {
            if (res.code == 200) {
              this.querying = false;
              this.showPopup("提交成功！我们将安排专人与您联系，请注意接听电话。感谢您的信任！");
            }
          })
          .catch(() => {
            this.querying = false;
          });
      });
    },
  },
};
</script>

<style scoped>
.submit-btn {
  margin-top: 50px;
}
.main {
  width: 80%;
  margin: 0 auto;
}
.popup-message {
  padding: 30px 0;
  color: #ffffff;
  background-color: #ff976a;
  font-size: 36px;
  text-align: center;
}
</style>