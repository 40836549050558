import request from '../util/request'

export default {
    save(formData) {
        return request({
            url: '/booking/save',
            method: 'POST',
            data: formData
        })
    }
}