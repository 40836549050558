<template>
  <van-popup
    v-model="showPopup"
    round
    position="bottom"
    class="tax-grade-selector"
  >
    <van-picker
      title="选择性别"
      show-toolbar
      :columns="taxGradeItems"
      @confirm="onConfirm"
      @cancel="onCancel"
    />
  </van-popup>
</template>

<script>
import { Popup, Picker } from "vant";

export default {
  name: "GenderSelector",
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
  data() {
    return {
      showPopup: false,
      taxGrade: "",
      taxGradeItems: ["先生", "女士"],
    };
  },
  created() {
    this.close();
  },
  methods: {
    open() {
      this.showPopup = true;
    },
    close() {
      this.showPopup = false;
    },
    onConfirm(value) {
      this.taxGrade = value === "无" ? "" : value;
      this.close();
      this.$emit("input", this.taxGrade);
    },
    onCancel() {
      this.close();
    },
  },
};
</script>

<style scoped>
.tax-grade-selector {
  padding: 30px 0;
  color: #ffffff;
  font-size: 36px;
  text-align: center;
}
</style>